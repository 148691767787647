import React from 'react';
import Image from './image';
import Navbar from './navbar';

const MainHeader = () => (
    <header className='hero is-link is-fullheight' style={{ position: `relative` }}>
      <Navbar/>
      <Image/>
      <div className='hero-body'>
        <div className='container'>
          <p>6 kwietnia 2019, Borowa k. Wrocławia</p>
          <h1 className='title is-1'>FESTIWAL TAŃCA</h1>
          <h1 className='title is-1'>TRENDY</h1>
          <a href='https://www.facebook.com/pg/festiwalTrendy/photos/?tab=album&album_id=384842698778470'
              target='_blank'
              className='button is-danger is-large'>Fotorelacja 2019</a>
        </div>
      </div>
      <div className='hero-foot has-text-centered is-size-4' style={{zIndex: `1`}}>
        <i className="fas fa-chevron-down"></i>
      </div>
    </header>
);

export default MainHeader;